<template>
  <Dialog>
    <template v-if="product.productSubscription.discount" #headline-suffix
      ><SubscripitonDiscountBadge
        :discount="product.productSubscription.discount"
    /></template>
    <Form
      id="product-subscription-reactivate-form"
      submit-label="shop.productSubscription.reactive.title"
      :btn-style="EButtonStyle.CTA"
      cancel-label="formFields.form.cancel"
      @submit="
        onSubmit(data.subscription, interval, quantity, desiredDeliveryDay)
      "
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
    >
      <template #default>
        <div class="product-grid">
          <div class="flex product-grid__image">
            <div class="relative w-[65px] sm:w-[150px] h-auto shrink-0">
              <ProductImage
                :image-url="product?.media?.cover"
                :alt-text="product?.media?.alt"
                class="w-[65px] h-[65px] sm:h-[120px] sm:w-[120px] object-scale-down mr-auto"
              />
            </div>
          </div>
          <div class="product-grid__productInfo">
            <div class="font-bold text-md md:text-lg font-deco-alt">
              {{ product.name }}
            </div>
            <div class="text-sm md:text-md">
              <span v-if="product.variantName">
                {{ product.variantName }}
                <span v-if="product.manufacturer"> | </span>
              </span>
              <span v-if="product.manufacturer">
                {{ product.manufacturer.name }}
              </span>
            </div>
            <div class="text-sm md:text-md">
              <span
                >{{ t('shop.cart.productFinder.itemNumber') }}
                {{ product.productNumber }}</span
              >
            </div>
          </div>
          <div class="text-sm product-grid__price md:text-md">
            <SubscripitonPrice
              :product="product"
              :dialog="true"
              :quantity="quantity"
            />
          </div>
        </div>
        <ContentSeparator class="relative mt-md separator-overwrite" />
        <div class="productEdit-grid">
          <div class="productEdit-grid__quantity">
            <div class="flex flex-col w-full flex-nowrap">
              <span class="font-bold">{{ t('shop.cart.general.amount') }}</span>
              <QuantityPicker
                :key="quantity"
                v-model="quantity"
                class="max-w-[100px] sm:max-w-[150px] w-full"
                :lower-limit="product.productSubscription.lowerLimit"
                :step-size="product.productSubscription.increaseQuantity"
                :debounce-emit="500"
                @click.prevent
                @update:model-value="(nv) => (quantity = nv)"
              />
            </div>
          </div>
          <div class="productEdit-grid__interval">
            <Dropdown
              id="aboInterval"
              v-model="interval"
              :value="deliveryIntervals[2]"
              :options="deliveryIntervals"
              name="aboInterval"
              :label="t('shop.pdp.productSubscription.aboInterval')"
            ></Dropdown>
          </div>
          <div class="productEdit-grid__delivery">
            <Dropdown
              id="aboDeliveryDay"
              v-model="desiredDeliveryDay"
              :value="deliveryDays[3]"
              :options="deliveryDays"
              name="aboDeliveryDay"
              :label="t('shop.pdp.productSubscription.deliveryDay')"
            >
              <template #info>
                {{ t('shop.pdp.productSubscription.edit.info') }}
              </template>
            </Dropdown>
          </div>
          <div class="productEdit-grid__infoBanner">
            <div
              v-if="!disabled"
              class="flex h-full text-sm px-sm md:text-md bg-background-base rounded-alt-md"
            >
              <div class="flex items-center icon-box bg-grey-light">
                <FaIcon
                  icon-class="fal fa-truck-fast"
                  classes="text-black mx-xs w-sm h-sm md:h-[20px] md:w-[20px]"
                />
              </div>
              <div class="my-xs mx-sm">
                <div>
                  {{ t('shop.productSubscription.reactivate.firstOrder') }}
                  <span class="font-bold text-status-success-dark">{{
                    t('shop.productSubscription.reactivate.hint')
                  }}</span>
                </div>
                <div>
                  {{ t('shop.productSubscription.reactivate.secondOrder') }}
                  <span class="font-bold text-status-success-dark">{{
                    nextOrderDate
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Form>
    <div class="px-md leading-2xs md:leading-sm">
      <Trans
        keypath="shop.productSubscription.agb"
        tag="span"
        class="text-xs md:text-sm pb-sm"
      >
        <NuxtLink
          :to="t('forms.agb.slug')"
          class="font-bold text-primary-base"
          target="_blank"
          >{{ t('forms.agb') }}</NuxtLink
        >
        <NuxtLink
          :to="t('shop.productSubscription.conditions.slug')"
          class="font-bold text-primary-base"
          target="_blank"
          >{{ t('shop.productSubscription.conditions') }}</NuxtLink
        >
        <NuxtLink
          :to="t('forms.privacy.slug')"
          class="font-bold text-primary-base"
          target="_blank"
          >{{ t('shop.productSubscription.privacy') }}</NuxtLink
        >
        <a
          :href="
            'javascript:' + linkTo_UnCryptMailto(`mailto:${mailAddress}`, -13)
          "
          class="font-bold text-primary-base"
          @click="openMailer(mailAddress)"
        >
          {{ t('shop.productSubscription.contradict') }}
        </a>
      </Trans>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import { useToastStore } from '@/stores/useToastStore';
import { Dropdown, QuantityPicker, ProductImage } from '@/complib';
import FaIcon from '@/components/fa-icon.vue';
import { EButtonStyle } from '~~/src/@types/basic-button';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import {
  useSubscriptionBox,
  useSubscriptionDeliveryNow,
} from '@/components/page/product/components/buyBox/components/options/subscription/useSubscriptionBox';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import { useProductSubscriptionEdit } from '@/components/dialog/productSubscriptionEdit/useProductSubscriptionEdit';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import Trans from '~/components/Trans.vue';
import { linkTo_UnCryptMailto } from '@/helpers/encryptMail';
import ContentSeparator from '@/components/block/seperator/contentSeparator.vue';
import type { Product } from '~/server/transformers/shop/product/types';
import { handleLoadingError } from '~/utils/handleLoadingError';
import SubscripitonDiscountBadge from '@/components/page/product/components/buyBox/components/options/subscription/components/subscriptionDiscountBadge.vue';
import SubscripitonPrice from '@/components/page/product/components/buyBox/components/options/subscription/components/subscription-price.vue';

const { t } = useTrans();
const dialogStore = useDialogStore();
const toastStore = useToastStore();
dialogStore.setHeadline(t('shop.productSubscription.reactivate.title'));
const data = dialogStore.getDialogData<{
  subscription: ProductSubscriptionItem;
}>();

const { nextOrderDate, disabled } = useSubscriptionDeliveryNow(
  data.subscription,
);
const { quantity, interval, desiredDeliveryDay, getNewDeliveryDate } =
  useProductSubscriptionEdit(data.subscription);
const product = ref<Product>(data.subscription.product);
const { deliveryIntervals, deliveryDays } = useSubscriptionBox({
  product: product.value as Product,
});

function openMailer(mail: string) {
  window.location.href = 'mailto:' + mail;
}

const mailAddress =
  useSiteIdent() === SiteIdent.default
    ? 'datenschutz@vanderven.de'
    : 'datenschutz@minilu.de';

watch(
  () => [interval.value, desiredDeliveryDay.value],
  async () => {
    const newDate = await getNewDeliveryDate(
      data.subscription.id,
      interval.value,
      desiredDeliveryDay.value,
    );
    nextOrderDate.value = newDate;
  },
);

async function onSubmit(
  subscription: ProductSubscriptionItem,
  interval: string,
  quantity: number,
  desiredDeliveryDay: string,
) {
  try {
    const site = useSiteIdent();
    const deliveryDay: number = +desiredDeliveryDay;
    const response = await useSecureSessionPost<string>(
      `/api/${site}/shop/product/productSubscription/updateCanceled/${subscription.id}`,
      {
        interval: interval,
        quantity: quantity,
        desiredDeliveryDay: deliveryDay,
        canceled: false,
      },
    );
    if (response) {
      toastStore.success({
        text: t('shop.productSubscription.reactivate.notification', {
          name: subscription.product.name,
        }),
      });
      dialogStore.closeDialog(DialogResponses.SUCCESS);
    } else {
      dialogStore.closeDialog(DialogResponses.CANCEL);
    }
  } catch (e) {
    dialogStore.closeDialog(DialogResponses.CANCEL);
    handleLoadingError(e);
  }
}
</script>
<style scoped lang="postcss">
.product-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'image productInfo'
    'image price';

  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}

.productEdit-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'quantity quantity'
    'interval interval'
    'delivery delivery'
    'infoBanner infoBanner';
  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}
.product-grid__image {
  grid-area: image;
  @apply mr-sm mt-2xs;
}
.product-grid__productInfo {
  grid-area: productInfo;
  @apply pl-sm;
}
.productEdit-grid__interval {
  grid-area: interval;
  @apply my-sm;
}
.productEdit-grid__quantity {
  grid-area: quantity;
  @apply my-sm;
}
.productEdit-grid__delivery {
  grid-area: delivery;
}

.productEdit-grid__infoBanner {
  grid-area: infoBanner;
  @apply my-sm;
}

.product-grid__price {
  grid-area: price;
  @apply items-start my-sm pl-sm  justify-center;
}

.separator-overwrite :deep(.separator) {
  @apply !pb-md !px-0;
}

@media (min-width: 960px) {
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'image productInfo'
      'image price';

    grid-template-columns: 150px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .productEdit-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'quantity interval'
      'delivery delivery'
      'infoBanner infoBanner';
    grid-template-columns: 200px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .product-grid__image {
    grid-area: image;
    @apply mr-sm mt-2xs;
  }
  .product-grid__productInfo {
    grid-area: productInfo;
  }
  .productEdit-grid__quantity {
    grid-area: quantity;
    @apply my-sm;
  }

  .productEdit-grid__interval {
    grid-area: interval;
    @apply my-sm pl-sm;
  }
  .product-gridEdit__delivery {
    grid-area: delivery;
  }

  .product-gridEdit__infoBanner {
    grid-area: infoBanner;
    @apply rounded-alt-md my-sm;
  }
  .product-grid__price {
    grid-area: price;
    @apply items-start my-sm  justify-center;
  }
}
</style>
